<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <!-- <router-view/> -->
  <div class="main">
    <div class="comming-soon">
      <img src="@/assets/logo.svg" alt="">
      <h1>MiRestaurante</h1>
      <span>
        <span class="bold">
          ¡Muy Pronto!
        </span>
        Te preocupas por tus clientes, nosotros por ti. Todo lo que necesitas justo a tiempo y al mejor precio
      </span>
    </div>
  </div>
</template>

<style lang="scss">
.main {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: rgb(253,143,0);
  background: linear-gradient(90deg, rgba(253,143,0,1) 0%, rgba(253,215,0,1) 50%, rgba(253,143,0,1) 100%);
  // Flex center
  display: flex;
  justify-content: center;
  align-items: center;


  .comming-soon {
    h1, p, span {
      text-align: center;
    }
    h1 {
      font-size: 3rem;
      color: #001733;
      font-weight: bold;
      margin: 2rem 0 1rem 0;
    }
    span {
      font-size: 1rem;
      color: #001733;
    }
    span.bold {
      font-weight: bold;
    }
    img {
      max-width: 75%;
      width: 8rem;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    max-width: 85%;
    width: 24rem;
    min-height: 24rem;

    background: #fff;

    // background: rgba( 255, 255, 255, 0.25 );
    // box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    // backdrop-filter: blur( 4px );
    // -webkit-backdrop-filter: blur( 4px );
    // border-radius: 10px;
    // border: 1px solid rgba( 255, 255, 255, 0.18 );


    border-radius: 2rem;
    padding: 2rem;

    // Shadow
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.2);
  }
}
</style>
